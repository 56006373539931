<template>
<v-app  id ="app">


  <div v-if="teamFolder">
<NavBar  :TeamFolder="teamFolder" />
<TeamBar v-if="computedRoute == '/DOWNLOADS'"  :TeamFolder="teamFolder"/>
  </div>
<div :style="computedBackground" >

<!-- <TeamBar v-if="computedRoute != '/DOWNLOADS'" /> -->




 <!-- <video controls>
      <source :src="'https://phntm-chase-backend-548acfa02253.herokuapp.com/s3Download?fileName=NHL_New-York_Rangers/20231016/Social/10_09_2023_6.mp4'" type="video/mp4">
      Your browser does not support the video tag.
    </video>   -->


<router-view ></router-view>
<!-- <router-view @report-height="updateHeight"></router-view> -->



     <div class="footer"
     v-if="computedRoute == '/SIGNIN'"
  style="font-family: OpenSans ; color: rgba(255, 255, 255, 0.60); font-size: 1.2rem;
font-style: normal;
font-weight: 200;
line-height: normal;">

    Your image, likeness, and data will not be shared or used for commercial purposes.

  </div>

</div>
</v-app>
</template>

<script>
import NavBar from './components/NavBar.vue';
import TeamBar from './components/TeamBar.vue';
import api from '../axiosConfig'




import { ref, onMounted, onUnmounted , watch} from 'vue';

export default {
  name: 'App',
     data: ()=>({
      FileList:[],
      ListOfEvents:[],
      NameBarHeighAdjustment: 0,
          
          eventLink:null,
   
    }),

  components: {
    NavBar,TeamBar
  },
   setup() {
 
    const isWidth = ref(window.innerWidth);
    const isHeight = ref(window.innerHeight);
    const isMobile =  ref((isWidth.value < 768 )|| ((isHeight.value  < isWidth.value  ) && isHeight.value  < 500 )  ? true : false)

    const updateWidth = () => {
      isWidth.value = window.innerWidth
      isHeight.value =window.innerHeight
      isMobile.value =  (isWidth.value < 768 )|| ((isHeight.value  < isWidth.value  ) && isHeight.value  < 500 )  ? true : false
    };

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return { isMobile ,isWidth , isHeight};
  },

  methods:{   goToAnotherPage() {
      this.$router.push({ path: '/AccountCreated' });
    },
    updateHeight(x){
      console.log('LogHeight',x)
      this.NameBarHeighAdjustment = x
    },

                teamFolderDecod(x){
        const teamMap = [
               {Team: 'newyorkrangers', Name: 'NHL_New-York_Rangers'}, 
               {Team: 'newyorkknicks', Name: 'NBA_New-York_Knicks'}, 
               {Team: 'goldenstatewarriors', Name: 'NBA_Golden-State_Warriors'}, 
                    ]
        var teamLookup= {}
           teamMap.forEach(item => {
              teamLookup[item.Team] = item.Name;
          });

      console.log('Team',x)
        return  teamLookup[x];

      },
    },
    computed:{




      teamFolder(){

        var TeamName  = this.computedQuery.Team

      if (!TeamName)
      {
       try {
           const x = this.$route.query.Content == 'test' ? 'TkhMX05ldy1Zb3JrX1JhbmdlcnMvMjAyMzEwMTYvU29jaWFsL0VyaWMtRXJpY2tzb25fN18xNTlfMzQzMTM0MzQzNC5tcDQ=' : this.$route.query.Content
           const decodedString2 = decodeURIComponent(escape(atob(x)));
           const firstSlashIndex = decodedString2.indexOf('/');
           TeamName = decodedString2.substring(0, firstSlashIndex);
           console.log('TESTE',TeamName)
        }
        catch{
           TeamName = 'genericteam'
        }
      }
      else{
        TeamName = this.teamFolderDecod(TeamName)
      }
        console.log('TeamName IS',TeamName)

        const array =  ['NBA_Golden-State_Warriors','NBA_New-York_Knicks','NHL_New-York_Rangers'] //this.ListOfEvents
        const exists = array.includes(TeamName);
        console.log('exists',exists)
        const teamReturn = exists ? TeamName : 'genericteam'
        console.log('teamReturn',teamReturn)
        console.log('decode',this.teamFolderDecod(TeamName))
        return teamReturn
        // return 'NBA_Golden-State_Warriors'
  
      },
       computedRoute() {
      return  this.$route.path.toUpperCase() ;
       },
           computedQuery() {
        
      
      return  this.$route.query ;
      
       }
      ,
           computedBackground() {
            
            let style = {}
            style.backgroundSize = 'cover'
            style.backgroundRepeat = 'no-repeat'
            style.backgroundPosition= 'center center'
            style.height = '100%'
            // style.backgroundSize = '90%'

          console.log('TeamIS',this.teamFolder)
          console.log('RouteIS',this.computedRoute)
            var backgrounURL = require('@/assets/genericteam/BackgroundSignIn.png');
           if (this.computedRoute == '/DOWNLOADS' && this.teamFolder)
           {
                backgrounURL = require('@/assets/'+this.teamFolder+'/BackgroundDownload1.png');
                style.backgroundPositionY = '0px' 
           }
            else if (this.computedRoute == '/ACCOUNTCREATED' && this.teamFolder)
           {
              backgrounURL = require('@/assets/'+this.teamFolder+'/BackgroundAccountCreated.png');
              // style.backgroundPositionY =  ( this.NameBarHeighAdjustment) + 'px' //  '100px' 
              style.backgroundPositionY = '0px' 
           }
            else if ((this.computedRoute == '/SIGNIN' || this.computedRoute == '/TEXTMANAGER')  && this.teamFolder)
           {
              backgrounURL = require('@/assets/'+this.teamFolder+'/BackgroundSignIn.png');
              // style.backgroundPositionY =  ( this.NameBarHeighAdjustment) + 'px' //  '100px' 
              style.backgroundPositionY = '0px' 
           }

           console.log('ChosenURL',backgrounURL)

           style.backgroundImage = `url(${backgrounURL})`

      
        return style
      
      
    },
    }

,
async created(){

    watch(
      () => this.computedQuery,
      async (newValue) => {
        if (newValue && this.computedQuery) {
          // await this.someAsyncFunction();
          // this.fetchFirstNameCSV()
          console.log('go')
        }
      },
      { immediate: true }
    );

  api.get('pullEventNames').then((x)=>this.ListOfEvents = x.data[0].EventName).catch((e)=>console.log('error pulling event',e))
  
}
   
}
</script>

<style>

@font-face {
  font-family: 'OpenSans Bold';  /* Define a name for the font */
  src: 
       url('./style/OpenSans/OpenSans_Condensed-Bold.ttf') format('truetype');  /* Path to the font files */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';  /* Define a name for the font */
  src: 
       url('./style/OpenSans/OpenSans_Condensed-Light.ttf') format('truetype');  /* Path to the font files */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans Light';  /* Define a name for the font */
  src: 
       url('./style/OpenSans/OpenSans-Light.ttf') format('truetype');  /* Path to the font files */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans Medium';  /* Define a name for the font */
  src: 
       url('./style/OpenSans/OpenSans-Medium.ttf') format('truetype');  /* Path to the font files */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans OG';  /* Define a name for the font */
  src: 
       url('./style/OpenSans/OpenSans-Bold.ttf') format('truetype');  /* Path to the font files */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans Regular';  /* Define a name for the font */
  src: 
       url('./style/OpenSans/OpenSans-Bold.ttf') format('truetype');  /* Path to the font files */
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'OpenSans SemiBold';  /* Define a name for the font */
  src: 
       url('./style/OpenSans/OpenSans-SemiBold.ttf') format('truetype');  /* Path to the font files */
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'OpenSans ExtraBold';  /* Define a name for the font */
  src: 
       url('./style/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');  /* Path to the font files */
  font-weight: normal;
  font-style: normal;
}



.footer{
  
  /* position: fixed; */
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 100px;
  color: white; /* Customize the text color */
  text-align: center;
  padding: 10px 0; /* Add padding as needed */
}

.CenterThis{
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CenterThisNoFlex{
  width:100%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  background-color: black;
}


.signupBox{
  /* display: flex; */
/* width: 950px; */
padding: 8px;
/* flex-direction: column; */
/* align-items: flex-start; */
gap: 10px;
border-radius: 12px;
background: var(--basic-white, #FFF);
position: relative;
}



.nameBox{
  /* display: flex; */
/* width: 423.275px; */

/* align-items: center; */
/* gap: 10px; */
/* margin-right: 32px ; */
border-radius: 8px;
border: 0.5px solid var(--brand-ui-typography, #414042);
background: var(--grayscale-100, #FBF9FA);
 margin: 8px;
color: var(--button-button-text, #666);
font-family: OpenSans;
  padding: 8px 18px;
font-weight: 600;
font-style: normal;
line-height: normal; 

}

.nameBox::placeholder {
  color: #666; /* Change the text color to gray */
  
  
}


.nameboxWeb{
  font-size: 16px;
  padding: 8px 18px;
}





.submitText{
  color: var(--grayscale-200, #E9ECEF);
  
font-family: OpenSans Light;

font-weight: 600;
font-style: normal;
line-height: normal; 

}

.submit{
  display: flex;
width: 300px;
padding: 14px 22px;
justify-content: center;
align-items: center;
gap: 10px;
/* position:fixed; */
border-radius: 8px;



right:338px;
}


.nameHeader{
  color:  #1D428A ; 
  /* var(--brand-ui-blue-1, #126BC5); */

/* text-align: center; */
font-family: OpenSans ExtraBold;
font-size: 4vh;
font-style: normal;
font-weight: 800;
/* line-height: normal; */
text-transform: uppercase;
/* z-index: 15; */
}

.hover {
  cursor: pointer;
}

</style>
