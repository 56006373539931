<template>
    <div class="text-center" style="align-items:center;justify-content:center;height:80vh;display:flex">
        <div>
  <h1 style="font-family:OpenSans Bold; font-size:30px;text-transform:uppercase;color:#FFF">Thanks for your participation</h1>
  
  <br/>  
  <br/>
  <h1 style="font-family:OpenSans ; font-size:20px;color:#FFF">Download of content is no longer available</h1>
        </div>

   </div>

   
</template>

<script>
export default {

}
</script>

<style>

</style>