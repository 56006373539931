<template>
<div>


 <v-dialog style="background:#084CA8" width="80%" v-model="deleteConfirm" >
    <v-card class="text-center">
        
        <v-card-title style="color:#CE1126; font-size:28px">Delete SQLite Users Confirmation</v-card-title>
        <span style="font-size:22px"> Confirm you would like to delete the Users from the Local Database (SQLite DB). This action can not be undone.
        </span>

       <br/>

         <div class="text-center CenterThis">
                  <div      class="submit "      @click="deleteConfirm = false" ref="submitButton" tabindex="0" style="background-color:green; margin-right:24px">
                        <div  class="submitText"  >Nevermind</div>
                </div>

                     <div      class="submit "      @click="deleteUsers()" ref="submitButton" tabindex="0" style="background-color:#CE1126">
                        <div  class="submitText"  >Yes, Delete Users</div>
                </div>
         </div>
         <br/>

    </v-card>
 </v-dialog>

<br/><br/><br/><br/><br/><br/>

    <div class="text-center">
          <h1 class="header" style="text-transform:uppercase">Setup Offline Mode</h1>
        

    <h1 class="header">Connected to SQL Lite Database:


    
 <v-icon v-if="backendDB == true" color="green">mdi-check-circle</v-icon> 
 <v-icon v-else-if="backendDB == false" color="red">mdi-cancel</v-icon>
  <v-img   v-else
      :src="gifUrl"
      aspect-ratio="15.7"
      contain
    ></v-img>
   
        </h1>

    

           <h1 class="header">Connected to Cloud Database:  
       
 <v-icon v-if="CloudDB == true" color="green">mdi-check-circle</v-icon> 
 <v-icon v-else-if="CloudDB == false" color="red">mdi-cancel</v-icon>
  <v-img   v-else
      :src="gifUrl"
      aspect-ratio="15.7"
      contain
    ></v-img>

  

        </h1>
        <br/>
        <v-divider color="white" thickness="10" />

    </div>

    

 <div class="text-center">
          
            <span class="headerText" style="font-size:30px">
                
             
                1.  Clear out the Local Database </span> <br/><br/>
                <div class="CenterThis">
                                    <div
      class="submit "
      @click="deleteConfirm = true"
      
       ref="submitButton"
      tabindex="0"
      style="background-color:#CE1126; margin-left:24px"
      >

    <div  
    
    class="submitText"  >Delete Local Database Users</div>
  </div>
         
                </div>

                <br/>
                
                 <span class="headerText" style="font-size:30px"> 2.  Pulling in Mobile User Signups (from Cloud Database)
                
            </span>

 

 </div>
  

      

  <div class="text-center">
         <h1 class="header" style="color:#b8b4b4;text-decoration-line: underline;">Optional - </h1>
         <h2 class="header" style="color:#b8b4b4;">Pull Users From Cloud Database and Load into Local SQLite Database </h2>
<br/>
          <div class="CenterThis">  
               <h1 class="headerText" style="font-size:2rem">
                <v-icon x-large color="green">mdi-numeric-1-circle</v-icon>
                Select Date 
                <br/>
                <input style="background-color:#b8b4b4; border-radius:5px;width:100%;height:50px; margin-right:80px;
                font-size:25px" type="date" v-model="date"  /></h1>
                

               <h1 class="headerText" style="font-size:2rem"> 
                <v-icon x-large color="green">mdi-numeric-2-circle</v-icon>
                Select Event 
                <br/>
                   <select style="background-color:#b8b4b4; border-radius:5px; width:100%;height:50px;margin-top:12px;
                   font-size:25px" v-model="selectedTeam">
      <option value="newyorkrangers">New York Rangers</option>
      <option value="newyorkknicks">New York Knicks</option>
      <option value="goldenstatewarriors">Golden State Warriors</option>
    </select>
               </h1>
</div>
               <br/><br/>

               <div class="text-center CenterThis">

                <div v-if="!loading && selectedTeam && date"
      class="submit "
      @click="TransferUsers"
      
       ref="submitButton"
      tabindex="0"
      style="background-color:#0B6EFD"
      >

    <div  
    
    class="submitText"  > 
    Pull in Mobile Users

    
    
    </div>
  </div>
      
               <v-img v-else-if="loading"
      :src="gifUrl"
      aspect-ratio="9.7"
      contain
    ></v-img>
         
     

</div>


         <h1 class="headerText" style="text-decoration-line: underline;">SQL Lite Users</h1>
    <div class="table-container headerText">
          <div  class="table-row" v-for="(i,index) in userList" :key="index">
            
       
            <span style=" border-right: 2px solid #084CA8;color:black; " class="table-cell" >
              
              <span style="font-weight:bold;color:green" v-if="i.UserID <1000000000 " >Local SignUp: </span>
              <span style="font-weight:bold;" v-else >Mobile SignUp: </span>
              

                {{i.UserID}}
            </span>
            <span class="table-cell" style="color:black"> {{i.FirstName}} {{i.LastName}}</span>
          </div>
          <div v-if="userList.length == 0" style="font-size:20px">No Local DB Users</div>
        </div>

    
  
  </div>
                    
</div>

 
</template>

<script>
import api from '../../axiosConfig';

export default {
  data: () => ({
    backendDB: null,
    CloudDB: null,
    menu: false,
    date: null,
    selectedTeam:null,
    deleteConfirm:false,
    userList: [],
    gifUrl: require('@/assets/Loading Animation.gif'),
    loading:false,
    
  }),
  methods:{
    deleteUsers(){
        this.deleteConfirm = false
         api.get('DeleteAllUsers').then(()=>{
            
            api.get('getUsers').then((users)=>{
            this.userList= users.data
        }).catch((error) => { console.error('Error fetching the file list:', error); })

        }).catch((error) => { console.error('Error fetching the file list:', error); })

    },
    TransferUsers(){
        this.loading = true;

               api.post('TransferUsersCloudToLocal?EventDate=' + this.date + '&Event=' + this.selectedTeam)
    .then((res) => { console.log('Users', res.data)
    }).then(()=>{
        api.get('getUsers').then((users)=>{
            this.userList= users.data
            this.loading = false
        }).catch((error) => { console.error('Error fetching the file list:', error); })
    })
    .catch((error) => { console.error('Error fetching the file list:', error); 
     this.loading = false
      
    })
        
    }
  },

  async created(){

    
    //This NEEDS to be Heroku Cloud Server Change when ready for PROD
       api.get('getCloudDBUsers?EventDate=2023-10-17' + '&Event=AnyEvent')
    .then(() => {
        this.CloudDB = true
    })
    .catch((error) => { console.error('Error fetching the file list:', error); 
        this.CloudDB = false
    })
       
       

        api.get('getUsers').then((users)=>{
            this.userList= users.data;
            this.backendDB = true
        }).catch((error) => { console.error('Error fetching the file list:', error);
        this.backendDB = false
        })

  }
}
</script>

<style scoped>
.header{
      font-family: 'OpenSans Bold';
        color: #FFF;
    font-size: '3rem' ;   
}

.headerText{
      font-family: 'OpenSans';
        color: #FFF;
        font-size: 40px;

}


.table-container {
  width: 60%;
  margin: 0 auto;
}

.table-row {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  margin-bottom: 4px;
  padding: 0px;
}

.table-cell {
  flex: 1;
    margin-right: 4px;
  padding: 5px;
  text-align: left;
  font-size: 18px;
}

</style>