
import AccountCreated from './Pages/AccountCreated.vue';
import HomePage from './Pages/HomePage.vue'
import Download from './Pages/DownloadPage.vue'
import ParticipationPage from './Pages/ParticipationPage.vue'
import SignUpPage from './Pages/SignUpPage.vue'
import OperatorDashboard from './Pages/OperatorDashboard.vue'
import TextManager from './Pages/TextManager.vue'


const routes = [
  { path: '/', component: HomePage },
  { path: '/AccountCreated', component: AccountCreated },
  { path: '/Downloads', component: Download },
  { path: '/ThankYou', component: ParticipationPage },
  { path: '/SignIn', component: SignUpPage },
  { path: '/Operator', component: OperatorDashboard },
  { path: '/TextManager', component: TextManager },
  
];

export default routes;