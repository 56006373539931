<template>
  
        <div style="position: relative;">


  <!-- <svg v-if="!isMobile" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 2560 200" fill="none" style="position: relative; z-index: 0;">
<path d="M0 0H2560V200C2560 200 2011.9 150 1280 150C548.103 150 0 200 0 200V0Z" fill="#CE1126"/>
</svg> -->

<div class="text-center nameHeader" :style="BannerStyle">
  {{firstName ? (firstName == 'CSV-ERROR' ? 'WELCOME' : firstName) : '' }} 
  
</div>


</div>

</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import api from '../../axiosConfig'

export default {

  props:{
    
    TeamFolder:{type:String}
  },

  data:()=>({
    firstName: null,

  }),
  computed:{
    BannerColor(){
       if (this.TeamFolder == 'NHL_New-York_Rangers')
        return '#FFFFFF'
       else if (this.TeamFolder == 'NBA_Golden-State_Warriors')
        return '#FFC72C'
      else
         return '#FFFFFF'
    },
    BannerFont(){
       if (this.TeamFolder == 'NHL_New-York_Rangers')
        return '#0033A0'
       else if (this.TeamFolder == 'NBA_Golden-State_Warriors')
        return '#1D428A'
      else
         return '#1D428A'
    },
    BannerStyle(){
      let style = {}
      style.backgroundColor = this.BannerColor
      style.paddingTop = '1vh'
      style.paddingBottom = '1vh'
      style.color= this.BannerFont

      return style
      
    }
  
    
  }
,
     setup() {
    const isMobile = ref(window.innerWidth <= 768);
    const isWidth = ref(window.innerWidth);
    const isHeight = ref(window.innerHeight);

    const updateWidth = () => {
      isMobile.value = window.innerWidth <= 768;
      isWidth.value = window.innerWidth
      isHeight.value = window.innerHeight
    };

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return { isMobile , isWidth, isHeight};
  },

  async created(){
    
        // setTimeout(()=>{this.togglePlayback()},2000)
    var x = this.$route.query.Content == 'test' ? 'TkhMX05ldy1Zb3JrX1JhbmdlcnMvMjAyMzEwMTYvU29jaWFsL0VyaWMtRXJpY2tzb25fN18xNTlfMzQzMTM0MzQzNC5tcDQ=' : this.$route.query.Content
    // TkJBX0dvbGRlbi1TdGF0ZV9XYXJyaW9ycy8yMDIzMTAyNC9Tb2NpYWwvMjAyMzEwMjRfVmFuIE5vc3RyYW5kLm1wNA==
    console.log('Query',this.$route.query.Content)

    try {
      
     const encodedString = btoa(unescape(encodeURIComponent('NBA_Golden-State_Warriors/20231024/Social/Fred-Deegle_23_7_8055980943.mp4')));
    console.log('ReCoded:',encodedString); // Outputs the Base64-encoded string




    const decodedString2 = decodeURIComponent(escape(atob(x)));
    console.log('TeamBarDecod',decodedString2)
    this.eventLink = decodedString2
    
      api.get('getFileList?FolderName=' + this.eventLink ) //+ this.$route.query.EventName)
    .then((res) => { 
      console.log('size',res)
          if (res.data.length > 0)
            {
             const csvVersion = this.eventLink.replace(".mp4", ".csv")
              api.get('s3Download?fileName='+csvVersion
          ,{
          responseType: "text",
           })
          .then((csv)=>{
          try{
            console.log('CSV Donwload',csv)
       
          const csvRemoveHeader =  csv.data.split('\r\n')[1] //2nd row
          // console.log('DDDD',csvRemoveHeader)

           const headers =  csvRemoveHeader.split(',')[0];
          let x = headers.replace(/"/g, '');
          console.log('CSV Donwload2',x)
          this.firstName = x
          }
          catch(e){
            console.log('CSV Retrieval Error',e)
          }
          }).catch((ee)=>{
              console.log('Metadata Doesnt Exist',ee )
              this.firstName = 'CSV-ERROR'
              })
            }
          })   
    .catch((error) => { console.error('Error fetching the file list:', error); });
    
} catch (e) {
    console.error('Decoding failed:', e);

}
  }

}
</script>

<style>

</style>