<template>
<EventPickerModal :ForwardRoute="'/TextManager'" @pullUsers="pullUsers" />
<div class="CenterThis">
    <div  v-if="!loading" style="color:#FFF;font-weight:bold;">
        
    <h1 style="color:#FFF">Date :  {{$route.query.Date}}</h1>


    <br/>
    <v-icon color="yellow">mdi-star</v-icon>ShortList
    <v-icon color="green">mdi-check-circle</v-icon>Text Sent
    <v-switch
                v-model="autoSendText"
                inset
                color="green"
                :label="`Auto Send Texts: ${autoSendText ? 'ON' : 'OFF'} `"
              ></v-switch>


    </div>

</div>
   <div class="CenterThis" style="font-family:OpenSans;color:#FFF;">
      When ON, Texts will be sent out every 5 minutes. Page Refreshes every 60 seconds.




      
    </div>
   

          
             <!-- <input type="text"    prepend-icon="mdi-magnify" v-model="SearchTeam" class="nameBox" style="font-size:0.8rem" /> -->

  <div style="position:relative;width:100%">
 <div class="wrapper">
    <div class="inputgroup"> <span class="innericon searchicon"></span>

        <v-icon color="white">mdi-magnify</v-icon>
        <input v-model="SearchString" type="text" class="search" style="color:white" placeholder="Search..." />
    </div>
</div>
<br/><br/>
  </div>
    
  

  <div class="CenterThis" v-if="!loading" >


    
    <div class="tableHolder" style="margin-top:24px">
    <div class="headerRow">
    <div class="headerTable" :style="columnWidth(0)"> &emsp;</div>
    <div class="headerTable" :style="columnWidth(2)"><b>FirstName </b></div>
    <div class="headerTable" :style="columnWidth(2)"><b>LastName</b></div>
    <div v-if="!isMobile" class="headerTable" :style="columnWidth(2)" ><b>CreatedDate</b></div>
    <div class="headerTable" :style="columnWidth(1)"><b>Download</b></div>
    
    </div>

       <div v-for="i,index in finalUsersWithFilter" :key="index" class="tableRow">
    <div  :style="[{position:'relative'},columnWidth(0) ]" class="tableData">
           <!-- <v-checkbox
           
           class="custom-checkbox"
        :value="index"
        color="#084CA8"
        @change="toggleCheck(i)"
     :label="`${ i.UserID }`" 
      ></v-checkbox> -->

           <v-checkbox
           :style="[{  height: '0px' ,top:'-10px',position:'absolute'}]"
           class="custom-checkbox"
        :value="index"
        color="#084CA8"
        @change="toggleCheck(i)"
      ></v-checkbox>

      <!-- <v-icon style="position:absolute; margin-left:60px;margin-top:8px">mdi-information</v-icon> -->
      
    </div>
    <div :style="columnWidth(2)" class="tableData"  >{{i.FirstName}}
         <v-icon v-if="i.ShortList" color="yellow">mdi-star</v-icon>
         <v-icon v-if="i.TextSent" color="green">mdi-check-circle</v-icon>
          <br/> UserID: {{i.UserID}} 
    </div>
    <div :style="columnWidth(2)" class="tableData" >{{i.LastName}} <br/> Phone #: {{i.PhoneNumber}} </div>
    <div :style="columnWidth(2)"  v-if="!isMobile" class="tableData" >{{i.CreatedDate}}</div>
    <div :style="columnWidth(1)" class="tableData" >
       
            <div class="buttonBox hover" @click="downloadByFinalUserID(i)" >
            <v-icon  :size="13" color="#3B82F6">mdi-download</v-icon>
            <div v-if="!isMobile" class="buttonText">Download</div>
          </div>
    
    </div>
    
    </div>
    

    </div>



</div>

                   <div v-if="!loading" class="CenterThis" style="margin-top:24px"  @click="sendTextMessage" >
      <div      class="submit "    ref="submitButton" tabindex="0"  :style="sendTextButton">
    <div      class="submitText"  >Send Text</div>
  </div>

</div>

                   <div v-if="!loading" class="CenterThis" style="margin-top:24px"  @click="returnToEventPicker" >
      <div      class="submit "    ref="submitButton" tabindex="0" style="background-color:grey;font-color:black">
    <div      class="submitText"  >Change Date / Event</div>
  </div>

</div>

                   <div v-if="!loading" class="CenterThis" style="margin-top:24px"  @click="manuallyRefresh" >
      <div      class="submit "    ref="submitButton" tabindex="0" style="background-color:grey;font-color:black">
    <div      class="submitText"  >Manually Refresh</div>
  </div>

</div>


  <v-img   v-else
      :src="gifUrl"
      aspect-ratio="15.7"
      contain
    ></v-img>

      <div class="CenterThis"  v-if="loading" style="color:#FFF;font-weight:bold">
        <br/>
    ... One Moment ...
    </div>


</template>

<script>
import EventPickerModal from '@/components/EventPickerModal.vue';
import api from '../../axiosConfig'
import { ref, onMounted, onUnmounted } from 'vue';

export default {
    components:{EventPickerModal},
      watch: {
    autoSendText(newVal) {
      console.log('TextChanged',newVal)

       if (this.$route.query)
         api.post('updateAutoSendSlider?event='+ this.$route.query.Team + '&eventDate=' + this.$route.query.Date + '&TextBit='
        + (newVal ? 1 : 0 )
        ).then(()=>{
            api.get('getCSVBuiltUsers?event='+ this.$route.query.Team + '&eventDate=' + this.$route.query.Date).then((x)=>{
               this.finalUsers = x.data}).catch((e)=>{console.log('CSVUser Fetch Error: ',e)})
        }).catch((e)=>{console.log('auto update Error: ',e)})

               

    }
      },

    computed:{
      finalUsersWithFilter(){

        if (this.SearchString.length > 0)
          {
          let filteredArray = this.finalUsers.filter(obj => {
             return obj.FirstName.toLowerCase().includes(this.SearchString.toLowerCase()) || 
                    obj.LastName.toLowerCase().includes(this.SearchString.toLowerCase()) ||
                    obj.PhoneNumber.toString().includes(this.SearchString) ;

            //  return Object.values(obj).some(val => 
            //  val.toString().toLowerCase().includes(this.SearchString)
            //    );
              });

          return filteredArray
          }
        else
        {
          return this.finalUsers
        }

      },
      sendTextButton(){
        return this.checkedUsers.length > 0 ? {backgroundColor: '#0B6EFD'} : {backgroundColor: 'grey'}
      }
    },

    data: () => ({
        finalUsers: []
        ,autoSendText:false
        ,checkedUsers : []
        ,loading:false,
        gifUrl: require('@/assets/Loading Animation.gif'),
        SearchString:'',
    }),
    methods:{
        columnWidth(x){
          var newWidth = x == 1 ? 20 : (x == 0 ? 10 : 23.333)

          if (this.isMobile)
            newWidth = x == 1 ? 20 : (x == 0 ? 10 : 35)
          
          return {width: newWidth + '%'}
        },
        toggleCheck(i){
            var indexVal = this.checkedUsers.indexOf(i) 
            if (indexVal >= 0)
                this.checkedUsers.splice(indexVal,1)
            else
                this.checkedUsers.push(i)
            
        },
        returnToEventPicker(){
            this.$router.push({ path: '/TextManager' }).then(()=>{
                window.location.reload(true);
            })
            
        },
        manuallyRefresh(){
          
               this.loading = true
               api.get('refreshALL').then(()=>{
                if (this.$route.query)
                     api.get('getCSVBuiltUsers?event='+ this.$route.query.Team + '&eventDate=' + this.$route.query.Date).then((x)=>{
                     this.finalUsers = x.data
                     this.autoSendText = this.finalUsers[0].AutomateText
                     this.loading = false
                  }).catch((e)=>{console.log('CSVUser Fetch Error: ',e)})

                

               })
               
        },
        sendTextMessage(){

          if (this.checkedUsers.length > 0)
          {
          
            api.post('SendText',this.checkedUsers,{headers: {'Content-Type': 'application/json'}}
                    ).catch((e)=>console.log('SendText E',e))
    
            this.loading = true

            this.checkedUsers.forEach((e)=>{console.log('Connor Text',e)})
            this.checkedUsers = []

          //Lazy Refresh. No time to handle the Response Message
            setTimeout(()=>{
                    api.get('getCSVBuiltUsers?event='+ this.$route.query.Team + '&eventDate=' + this.$route.query.Date).then((x)=>{
               this.finalUsers = x.data
        }).catch((e)=>{console.log('CSVUser Fetch Error: ',e)})    },5000)

            setTimeout(()=>{this.loading = false},6000)
          }
            },
                downloadByFinalUserID(x){

      this.loading = true
       const mp4Version = x.DataSourceKey.replace(".csv", ".mp4")

       api.post('LogDownload?fileName=' + mp4Version)
    .then(() => { 
     
      const filname ='PlayerCapture.mp4'
      const link = document.createElement('a')
      const url = 'https://phntm-chase-backend-548acfa02253.herokuapp.com/'
      // const url = 'http://localhost:5000/'

      link.href = url + 's3Download?fileName=' + mp4Version
      
      link.download = filname  // Specify the desired filename here
      link.click()

         this.loading = false


    }).catch((error) => {console.log('Network or Server Error:',error)})

        

            console.log('Download Initiated for ',x)

        
        },
        pullUsers(s){
 
          console.log('PULLUSEr2',s)

           api.get('getCSVBuiltUsers?event='+ s.Team + '&eventDate=' + s.Date).then((x)=>{
               this.finalUsers = x.data
               this.autoSendText = this.finalUsers[0].AutomateText
        }).catch((e)=>{console.log('CSVUser Fetch Error: ',e)})

        }
    }
,
    async created(){

   if (this.$route.query)
    api.get('getCSVBuiltUsers?event='+ this.$route.query.Team + '&eventDate=' + this.$route.query.Date).then((x)=>{
               this.finalUsers = x.data
               this.autoSendText = this.finalUsers[0].AutomateText
               }).catch((e)=>{console.log('CSVUser Fetch Error: ',e)})
        
    setInterval(()=>{
      if (this.$route.query)
    api.get('getCSVBuiltUsers?event='+ this.$route.query.Team + '&eventDate=' + this.$route.query.Date).then((x)=>{
               this.finalUsers = x.data
               this.autoSendText = this.finalUsers[0].AutomateText
               }).catch((e)=>{console.log('CSVUser Fetch Error: ',e)})
    },60000)

    },
       setup() {
 
    const isWidth = ref(window.innerWidth);
    const isHeight = ref(window.innerHeight);
    const isMobile =  ref((isWidth.value < 768 )|| ((isHeight.value  < isWidth.value  ) && isHeight.value  < 500 )  ? true : false)

    const updateWidth = () => {
      isWidth.value = window.innerWidth
      isHeight.value =window.innerHeight
      isMobile.value =  (isWidth.value < 768 )|| ((isHeight.value  < isWidth.value  ) && isHeight.value  < 500 )  ? true : false
    };

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return { isMobile ,isWidth , isHeight};
  },

}
</script>

<style>

.tableHolder{
position:relative;background: var(--grayscale-300, #DEE2E6); 
   max-height: 60vh; 
  overflow-y: auto;
  width: 80%;
  border-radius: 10px;
}
.headerTable{
font-size: 1vh;
width:20%;
/* height: 169px; */
padding: 5px 4px;
/* align-items: center; */
gap: 0px;
flex-shrink: 0;
border-radius: 0px;
border: 3px solid var(--grayscale-400, #CED4DA);
background: var(--grayscale-300, #DEE2E6);

}

.headerRow{

 display: flex;
  justify-content: space-between;
padding: 0px 0px;

align-items: center;
gap: 0px;
/* flex-shrink: 0; */
border-radius: 5px;
border: 3px solid var(--grayscale-400, #CED4DA);
background: var(--grayscale-300, #DEE2E6);
}

.tableRow{
 display: flex;
  justify-content: space-between;
padding: 0px 0px;
height: 50px;

align-items: center;
/* gap: 10px; */
/* flex-shrink: 0; */
border-radius: 10px;
border: 3px solid var(--grayscale-400, #CED4DA);
background: var(--grayscale-300, #FFF);
}


.tableData{
    font-size: 1vh;
width:20%;
/* height: 169px; */
padding: 5px 4px;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 0px;
border: 3px solid var(--grayscale-400, #CED4DA);
height: 100%;
background: var(--grayscale-300, #FFF);

}


.buttonBox {
  display: inline-flex;
  height: 16px;
  padding: 8px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--blues-light-blue, #60a5fa);
  background: var(--text-primary-text, #f8fafc);
}

.buttonText {
  color: var(--primary-dark-theme-cta, #3b82f6);
  /* Button/Small */
  font-size: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
}

.custom-checkbox .v-label {
  font-size: 1vh !important;
}


.searchBox{
  box-sizing: border-box;

/* Auto layout */
position: relative;
display: flex;
flex-direction: row;
align-items: center;
padding: 4px 220px 4px 8px;
gap: 4px;

width: 139px;
height: 30px;

/* Grey / Lightest */

background: #F8FAFC;
/* Grey / Light */

border: 1px solid #E3E8EF;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;

}


body {
    color: white;
}
.wrapper {
    right:0px;
    position: absolute;
    margin: 20px auto;
    width: 40%;
}
* {
    box-sizing: border-box;
}
.inputgroup {
    position:relative;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
}
.search {
    border-radius: 8px;
    height: 30px;
    line-height: 30px;
    padding: 3px 6px 3px 32px;
    border: none;
    font-size: 13px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #CCC;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.inputgroup .innericon {
    position: absolute;
    top: 4px;
    left: 8px;
    z-index: 2;
    display: block;
    text-align: center;
    pointer-events: none;
}


</style>