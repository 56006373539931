
<template>
  <v-container >
          <!-- <v-alert v-if="isMobile && show" style="z-index:10;font-size:1.5rem"  border="top" color="green lighten-2" dark>
            Account created succesfully
          </v-alert>
              <v-alert style="z-index:10;font-size:1.5rem" v-else-if="show"  border="top" color="green lighten-2" dark>
            Account created succesfully
          </v-alert> -->

   

      
 

     

      <div class="CenterThis">
        

         <div class=" text-center">
<br/><br/><br/>

        <div class="headerBox" style="width:350px">
                 
       <h3 style="font-family: OpenSans;color: #E9ECEF; ;font-size:1.5rem;">We will text you a download link once your video is ready</h3>
        </div>
       <br/>

       <div class="playerBox  ">
        <div class="text-center">
              <h3 style="font-family: OpenSans Bold;color:#084CA8;font-size:3rem;text-transform:uppercase">{{firstName}}  {{lastName}} </h3>
              
              <h3 style="font-family: OpenSans Bold;color:#084CA8;font-size:200px;line-height:200px"> #{{Player}} </h3>
        </div>
       </div>

           <div class="text-center CenterThis ">
    <div  class="submit hover" style="width:300px" @click="goBack" >
      <span class="submitText" style="font-size: 20px;" >Back to Sign Up</span></div>
    </div>

      </div>
       

      </div>
 

  </v-container>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  components:{},
  name: 'AccountCreated',
  data: () => ({
    show:true,
    firstName:'as',
    lastName:null,
    occupation:null,
    Player:null
  }),
   setup() {
    const isMobile = ref(window.innerWidth <= 768);

    const updateWidth = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return { isMobile };
  },

  methods: {
    goBack() {
      this.$router.push({ path: '/SignIn', query: {Content: 'VmVyaWZpZWQ=',Team: this.$route.query.Team, Date: this.$route.query.Date} });
    },
     },
    async created(){

        setTimeout(()=>{this.show = false},3000)
      
        this.firstName = this.$route.query.firstName
        this.lastName = this.$route.query.lastName
        this.occupation = this.$route.query.occupation
        this.Player = this.$route.query.playerNumber
    }
 
}
</script>
<style scoped>
.submit{
  display: flex;
  margin-top: 28px;

height: 50px;
padding: 14px 22px;
justify-content: center;
align-items: center;
gap: 10px;
/* position:fixed; */
border-radius: 8px;
background: var(--button-emphasis-button-blue, #0B6EFD);
right:338px;
}


.headerBox{
  display: inline-flex;
/* padding: 10px 48px; */
flex-direction: column;
justify-content: center;
align-items: center;
/* gap: 4px;
border-radius: 16px; */

margin-bottom: 16px;
/* width: 340px; */
}

.playerBox{
  display: inline-flex;
padding: 10px 48px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 16px;
background: var(--rangers-white, #FFF);
margin-bottom: 16px;
/* width: 340px; */
}


</style>