<template>

 
<div style="height:8vh" class="nav-container">
  
  <img style="height:4vh" :src="teamLogoSrc"/>



<svg class="svg-item" xmlns="http://www.w3.org/2000/svg"  height="4.8vh" viewBox="0 0 3 50" fill="none">
 <path d="M1.41199 0H0.3125V110H1.41199V0Z" fill="#FFFFFF"/>
</svg>

<div class="svg-item">

<svg class="inner-svg-item" xmlns="http://www.w3.org/2000/svg"  height="2.4vh" viewBox="0 0 185 36" fill="none">
  <path d="M69.9106 0.871338V35.1184H63.0082V20.3975H44.0827V35.1184H37.2007V0.871338H44.0827V14.4521H63.0082V0.871338H69.9106ZM154.694 0.871338V35.1083H184.93L181.112 29.0814H161.606V20.4077H180.501V14.6048H161.606V6.76583H181.071L184.828 0.871338H154.683H154.694ZM11.5765 0.871338C4.40941 0.871338 0.785156 5.22858 0.785156 11.5812V24.3577C0.785156 31.6978 5.44781 35.1286 11.5357 35.1286H33.1591L29.1581 28.8676H12.8796C9.4182 28.8676 7.91149 27.6256 7.91149 23.7469V12.1513C7.91149 8.40488 9.18405 6.9389 12.9712 6.9389H29.321L33.1692 0.881517H11.5765V0.871338ZM124.458 0.871338C120.314 0.871338 116.069 3.36555 116.069 9.76907V11.4081C116.069 18.0763 120.151 20.6215 124.234 20.652H138.568C140.064 20.652 141.265 20.8963 141.265 23.4109V26.3225C141.235 28.5826 140.105 29.1018 138.517 29.1018H119.652L115.784 35.1286H138.965C144.554 35.1286 148.341 32.3494 148.341 25.9255V23.5738C148.341 17.3637 144.808 14.4317 139.352 14.4317H125.659C124.142 14.4317 123.083 14.0245 123.083 11.7746V9.41275C123.083 7.49882 123.816 6.79637 125.577 6.79637H143.526L147.282 0.891699H124.437L124.458 0.871338ZM96.7768 0.881517L113.045 35.1184H105.339L102.193 28.0634H84.5399L81.3737 35.1184H73.6977L89.9457 0.881517H96.7768ZM87.1461 22.2198H99.5866L93.346 7.99767L87.1461 22.2198Z" fill="white"/>
</svg>

<svg class="inner-svg-item" xmlns="http://www.w3.org/2000/svg" height="2.4vh"  viewBox="0 0 46 46" fill="none">
  <path d="M31.3398 0.506348L44.7373 13.2218H15.3259V2.0945C15.3259 1.21898 16.0284 0.506348 16.9141 0.506348H31.3398ZM45.8164 16.6017C45.8164 15.716 45.114 15.0135 44.2181 15.0135H33.101V44.4249L45.8164 31.0376V16.6119V16.6017ZM29.7109 45.4939C30.5865 45.4939 31.2991 44.7812 31.2991 43.9057V32.7785H1.88772L15.275 45.4939H29.7007H29.7109ZM0.808594 29.4087C0.808594 30.2842 1.52122 30.9969 2.39674 30.9969H13.524V1.58548L0.808594 14.983V29.4189V29.4087Z" fill="white"/>
</svg>
</div>


</div>
  

</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';


export default {
   name: 'NavBar',
   props:{
     TeamFolder:{type:String}
   },
   computed:{
    dynamicNavBar(){    if (this.isMobile){
          return {height:'100px'}
        }
        else{
          return {height:'200px'}
        }
    },
    teamLogoSrc(){
      
      if (this.TeamFolder )
          return require('@/assets/'+this.TeamFolder+'/Vector.svg')
      else (this.TeamFolder )
          return require('@/assets/genericteam/Vector.svg')
    }
   },
   setup() {
    const isMobile = ref(window.innerWidth <= 768);

    const updateWidth = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return { isMobile };
  },

  
}


</script>

<style>
.nav-container {

  padding: 1em;

    display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  z-index: 50;
   background-color: #084CA8;
  
}

.svg-item {
  margin: 0 8px;  /* 8px spacing between each SVG */
}

.inner-svg-item {
  margin: 0 4px;  /* 8px spacing between each SVG */
}

.company-name {
  font-size: 1.5em;
  color: #F5F5F5;
  margin-left: 120px;
  margin-top:24px;
  width: 129.138px;
height: 24px;
flex-shrink: 0;
}


</style>
