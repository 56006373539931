<template>

  <div style="position: relative;height:95%">
    
        <!-- White box -->

         <!-- v-if="firstName || firstName == 'CSV-ERROR'" -->
 
  <!-- <div ref="NameDiv"
  
   class="videoReady" >
    
    <div class="text-center textvideo textName">{{firstName == 'CSV-ERROR' ? 'WELCOME' : firstName }} </div>   
    
    <div class="text-center textvideo">YOUR VIDEO IS READY!</div>   </div> -->



    <!-- {{eventLink}}

     -->



  <div style="position:absolute;font-color:white;bottom:20px;z-index:100;width:100%">
    <div class="container">
  <div class="downloadStyle hover " @click="downloadMedia">    
          <v-img v-if="downloading" :src="gifUrl" aspect-ratio="9.7" contain></v-img>
      <div v-else class="downloadText">  Download </div>
  </div>
    </div>
    </div>  
 








  </div>
</template>

<script>
import api from '../../axiosConfig';

import { ref, onMounted, onUnmounted   } from 'vue';



export default {
  components:{},
  computed:{

           computedRoute() {
                return  this.$route.path.toUpperCase() ;
       }
      ,
    // downloadStyle(){
    //   return{
    //     // top: (this.isHeight < 500? this.isHeight /3 : (this.isHeight) / 3) + 'px',
    //      top: (this.isMobile ? (this.isHeight * 4/5) : (this.isHeight * 3/5) )  + 'px',
    //     width:'100%'
    //   }
    // }
  },
  methods:{
    logMedia(){

      this.downloading = true

       api.post('LogDownload?fileName=' + this.eventLink)
    .then(() => { 
     
      const filname ='PlayerCapture.mp4'
      const link = document.createElement('a')
      const url = 'https://phntm-chase-backend-548acfa02253.herokuapp.com/'
      // const url = 'http://localhost:5000/'


      


      link.href = url + 's3Download?fileName=' + this.eventLink
      
      link.download = filname  // Specify the desired filename here
      link.click()

         this.downloading = false


    }).catch((error) => {console.log('Network or Server Error:',error)})

    },
    downloadMedia(){
      this.logMedia()
 
    },
    //  togglePlayback() {
    //   const video = this.$refs.videoElement;
    //   if (video.paused) {
    //     video.play().catch(error => {console.log(error)})
    //   }
    //   //   this.buttonText = 'Pause';
    //   // } else {
    //   //   video.pause();
    //   //   this.buttonText = 'Play';
    //   // }
    // }
  },

  data: ()=> ({
    eventLink:null,

    userId:null,
    
    downloading:false,
      gifUrl: require('@/assets/Loading Animation.gif'),
  }),

  async created(){

          // setTimeout(()=>{this.togglePlayback()},2000)
    const x = this.$route.query.Content == 'test' ? 'TkhMX05ldy1Zb3JrX1JhbmdlcnMvMjAyMzEwMTYvU29jaWFsL0VyaWMtRXJpY2tzb25fN18xNTlfMzQzMTM0MzQzNC5tcDQ=' : this.$route.query.Content
    // TkJBX0dvbGRlbi1TdGF0ZV9XYXJyaW9ycy8yMDIzMTAyNC9Tb2NpYWwvMjAyMzEwMjRfVmFuIE5vc3RyYW5kLm1wNA==
    console.log('Query',this.$route.query.Content)

    try {
    const decodedString2 = decodeURIComponent(escape(atob(x)));
    console.log('OtherDecode',decodedString2)
    this.eventLink = decodedString2
    

      api.get('getFileList?FolderName=' + this.eventLink ) //+ this.$route.query.EventName)
    .then((res) => { 
          if (res.data.length == 0)
               //this.$router.push({ path: '/ThankYou'})
                console.log('none')
          })   
    .catch((error) => { console.error('Error fetching the file list:', error); });
    
} catch (e) {
    console.error('Decoding failed:', e);
    // this.$router.push({ path: '/ThankYou'})
}



  },

  setup() {  // setup(_, context) {

    const isMobile = ref(window.innerWidth <= 500);
    const isHeight = ref(window.innerHeight);
    const isWidth = ref(window.innerWidth);
    const NameDiv = ref(null)

    const updateWidth = () => {
      isMobile.value = window.innerWidth <= 500;
      isHeight.value = window.innerHeight;
      isWidth.value = window.innerWidth;
    };

    onMounted(() => {

      if (NameDiv.value)
      {
        // const targetDiv = NameDiv.value.clientHeight // this.$refs.NameDiv;   
        // context.emit('report-height', targetDiv);
      }
    
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return { isMobile, isHeight ,isWidth, NameDiv};
  },
  mounted(){
    // this.$nextTick(() => {
    //   if (this.$refs.NameDiv)
    //   {
    //     const targetDiv = this.$refs.NameDiv.clientHeight // this.$refs.NameDiv;   
    //  // Measure the height of the target div
    // const targetHeight = targetDiv.offsetHeight;
    // // Set other divs to match the width of the target div
    // this.$emit('report-height', targetHeight);
    //   }
    // else
    //    this.$emit('report-height', '9');
    // })

  }
}
</script>

<style>
video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}

#playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,0.6);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.videoReady{
  /* display: flex; */
/* height: 60px; */
/* height: 10%; */
/* height:8vh; */
padding: 7px 0px;
justify-content: center;
align-items: center;
gap: 10px;
background: var(--rangers-white, #FFF);
z-index: 15;
/* position: absolute; top: 0; left: 0; */
 width: 100%;background-color: white; 
}

.textvideo{
  color: var(--brand-ui-blue-1, #126BC5);
text-align: center;
font-family: OpenSans OG;
font-size: 18px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-transform: uppercase;
z-index: 15;


}



.logo-wrapper-image {
  margin: auto;
  position: absolute;
      right: 0;
      left: 0;
      top: -40px;
    z-index: 10;
      
}

.download-wrapper-image {
  margin: auto;
  position: absolute;
      right: 0;
      left: 0;
      top: 0px;
    z-index: 10;
      
}

.image-wrapper {
  position: relative;
  display: inline-block;
  z-index: 3;
  top:-20px;
}

.bottom-image {
  position: relative;
  z-index: 1;
}

.top-image {
  position: absolute;
   /* top: 50%;
  left: 50%;  */
  /* transform: translate(-50%, -50%); */
  z-index: 2;
}
.downloadStyle{
  display: flex;
width: 202px;
padding: 14px 22px;
justify-content: center;
align-items: center;
  
gap: 10px;
border-radius: 8px;
z-index: 10;
background: var(--button-emphasis-button-blue, #0B6EFD);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadText{
  color: var(--grayscale-200, #E9ECEF);
font-family:  OpenSans Medium;
font-size: 16px;
/* font-style: normal;
font-weight: 600;
line-height: normal; */
}

.textName{
  font-size: 28px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-transform: uppercase;
}


  .bottom-button {
    position: absolute;
   
    /* transform: translateX(-50%); */
}


</style>