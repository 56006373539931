<template>
  
  <SignUp/>
  <EventPickerModal :ForwardRoute="'/SignIn'" />
 
</template>

<script>
import EventPickerModal from '@/components/EventPickerModal.vue';
import SignUp from '../components/SignUp.vue'



export default {

    components:{SignUp,EventPickerModal}
}
   
</script>

<style>

</style>