<template>

  <div > 
      <v-alert style="z-index:10" v-if="show && ErrorMessage" border="top" color="red lighten-2" dark>
        {{ErrorMessage}}

    </v-alert>
    



    <div class="text-center">
<div  class="fullBox" >
      <br/><br/><br/>

       <div class="text-center" >
        
           <h4 class="text-center" :style="computedHeader" >
            <span ref="targetDiv" >Welcome {{fanName}} fans!</span>
    
   </h4>

     

   <br/>
 <div style="display: flex;
  justify-content: center;
  align-items: center; width:100%">
    <h1  class="text-left" :style="computedHeaderText" ref="otherDiv1">
        <span class="text-left"> The information provided below will be used to personalize your Fan Intro. Final content will be delivered to your phone via text message. Let’s go!
         <br/>
        
        </span>
    </h1>
  
</div>
       
       </div>
    

      
        <div  class="CenterThis">
          
          
        <div class="signupBox" ref="otherDiv2">
            <span style="font-family:OpenSans">{{$route.query.Date}}</span>

            <div class="tabsliderBox">
    <div :class="tabNameOrTeam == 'Name'? 'innertabSlider' : 'innertabSliderOther' " style="order:0;" @click="tabNameOrTeam = 'Name'">
      <span class="selectOne" style="left:25%;">First Name</span>
    </div>
    <div :class="tabNameOrTeam != 'Name'? 'innertabSlider' : 'innertabSliderOther' " style="order:1" @click="tabNameOrTeam = 'Team'"> 
        <span class="selectOne" style="right:25%" >Team Name</span>
    </div>
   
  </div>



         
        <input type="text" v-model="firstName" class="nameBox " :style="computedInputText" :placeholder="tabNameOrTeam == 'Name' ? 'First Name' : 'Team Name'"/>
          <span v-if="!isValidName" style="color:grey;font-size:0.7rem">Invalid Name <br/></span>


        <input type="text" v-if="tabNameOrTeam == 'Name'" v-model="lastName" class="nameBox " style="width:95%" placeholder="Last Name" />
          <span v-if="!isValidLastName" style="color:grey;font-size:0.7rem">Invalid Name <br/></span>

        <div class="CenterThis">
        <select class="nameBox " style="width:55%;color:#666"   v-model="playerNumber">
                    <option  disabled >Jersey #</option>

                    <option v-for="n in 100" :value="n-1" :key="n">{{ n-1 }}</option>
                  </select>

          <input @input="formatPhoneNumber(phoneNumber)" style="width:95%"  type="tel" v-model="phoneNumber" class="nameBox " placeholder="Phone Number"/>
        </div>

        </div>
        </div>

       

<div class="CenterThis hover"  >
      <div
      class="submit "
      ref="otherDiv3"
      @click="signup"
       :style="computedStyles">
       
          <v-img v-if="submitted"
      :src="gifUrl"
      aspect-ratio="9.7"
      contain
    ></v-img>
    <div v-else 
    
    class="submitText" :style="computedStyles" >Submit</div>
  </div>

</div>
  
 

  <br/><br/><br/><br/>



      <div >
    



      </div>

      


  
</div>
    </div>

     



  </div>  
</template>

<script>
import api from '../../axiosConfig';

import { ref, onMounted, onUnmounted } from 'vue';

export default {
  components:{},
  props: {
  },
  data: () => ({
    gifUrl: require('@/assets/Loading Animation.gif'),
    firstName: null,
    lastName: null,
    occupation: null,
    phoneNumber: null,
    playerNumber: 'Jersey #',
    show: false,
    ErrorMessage:null,
    submitted:false,
    tabNameOrTeam:'Name',
    timer: null,
  }),
   computed: {
        isValidName() {
      // Regex explanation:
      // ^ asserts position at start of the line
      // [a-z\s-] matches a lowercase letter, whitespace or dash
      // + matches between one and unlimited times
      // $ asserts position at the end of the line
      const regex =  /^[a-zA-Z\s-]+$/;
      if (this.firstName)
        return (regex.test(this.firstName) && (this.firstName? this.firstName.length : 0 ) <= 15)
      else
        return true
    },
            isValidLastName() {
      // Regex explanation:
      // ^ asserts position at start of the line
      // [a-z\s-] matches a lowercase letter, whitespace or dash
      // + matches between one and unlimited times
      // $ asserts position at the end of the line
      const regex =  /^[a-zA-Z\s-]+$/;
      if (this.lastName)
        return (regex.test(this.lastName) && (this.lastName? this.lastName.length : 0 ) <= 20)
      else
        return true
    },
      fanName(){
        const teamMap = [
               {Team: 'newyorkrangers', Name: 'Rangers'},
               {Team: 'newyorkknicks', Name: 'Knicks'},
               {Team: 'goldenstatewarriors', Name: 'Warriors'},
                    ]

        var teamLookup= {}

           teamMap.forEach(item => {
              teamLookup[item.Team] = item.Name;
          });

return  teamLookup[this.$route.query.Team];

      },



      colCompute(){
        return this.isWidth < 1000 ? 12 : 6
      },
        colComputeHeader(){
        return this.isWidth < 1000 ? 10 : 4
      },
         AccountFieldsCheck(){
      if (this.firstName && this.isValidName && this.isValidLastName && (this.tabNameOrTeam == 'Name' ? this.lastName : true)  && ( (this.playerNumber || this.playerNumber == 0) ? true : false )
      && (this.phoneNumber? this.phoneNumber.length == 12 : false) && this.isValidDateString(this.$route.query.Date)
      )
          return true
      else
          return false
    },

           computedInputText() {
        let styles = {};
  
        styles.width= '95%'
        styles.fontSize = '16px'

        if (this.isWidth > this.isHeight)
          styles.fontSize = '16px'
        
        return styles
    },

        computedHeaderText() {
        let styles = {};

  
        styles.fontFamily= 'OpenSans'
        styles.color= '#E9ECEF'
        styles.fontSize= '1.5rem' 
        // styles.padding = '10px';

        if (this.isWidth > this.isHeight)
          styles.fontSize = '1rem'
        
        return styles
    },

    computedHeader() {
        let styles = {};
  
        styles.fontFamily= 'OpenSans Bold'
        styles.color= '#FFF'
        styles.fontSize= '3rem' 

        if (this.isWidth > this.isHeight)
          styles.fontSize = '2rem'
        
        return styles
    },
        computedStyles() {
      let styles = {};
      

      if (this.AccountFieldsCheck ) 
        styles.backgroundColor = '#0B6EFD';    
      
      else{
       // styles.backgroundColor = 'lightgray'
         styles.backgroundColor =  '#778291' // '#778291';    
        // styles.color = '#666'
      }

      if (this.isMobile)
        styles.fontSize=  '16px'
      else
        styles.fontSize=  '16px'
      
      return styles;
    },
   },

   mounted(){
       // Get references to the target div and other divs
    const targetDiv = this.$refs.targetDiv;
    const otherDiv1 = this.$refs.otherDiv1;
    const otherDiv2 = this.$refs.otherDiv2;
    const otherDiv3 = this.$refs.otherDiv3;
    
     // Measure the width of the target div
    const targetWidth = targetDiv.offsetWidth;
    // Set other divs to match the width of the target div
    otherDiv1.style.width = `${targetWidth }px`;
    otherDiv2.style.width = `${targetWidth }px`;
    otherDiv3.style.width = `${targetWidth }px`;
  
   },


    setup() {
      
    const isMobile = ref(window.innerWidth <= 768);
    const isWidth = ref(window.innerWidth) ;
    const isHeight = ref(window.innerHeight) ;

    const updateWidth = () => {
      isMobile.value = window.innerWidth <= 768;
      isWidth.value =window.innerWidth ;
      isHeight.value = window.innerHeight;
    };

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    //   // Watch for changes
    // watch(windowWidth, (newWidth) => {
    //   isMobile.value = newWidth <= 390;
    // });


    return { isMobile ,isWidth, isHeight};
  },
  
  methods: {
     setTimer() {
      // Set the timer for 1 hour
      this.timer = setTimeout(() => {
        window.location.reload(true);
      }, 3600000);
    },
       resetTimer() {
      // Clear the existing timer and set a new one
      clearTimeout(this.timer);
      this.setTimer();
    },

    isValidDateString(dateStr) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateStr)) {
    return false;
  }

  const date = new Date(dateStr);
  return !isNaN(date.getTime());
},




  
formatPhoneNumber(currentRawNumbers) {
    
    let  isBackspace = currentRawNumbers.length < this.phoneNumber.length;
    currentRawNumbers = currentRawNumbers.replace(/\D/g, '');

  if (isBackspace && this.phoneNumber.endsWith('-')) {
    this.phoneNumber = this.phoneNumber.slice(0, -2)
    return
  }
  else if (isBackspace) {
    this.phoneNumber = this.phoneNumber.slice(0, -1)
    return
  }

  let formatted = currentRawNumbers.substring(0,3) + (currentRawNumbers.length > 3 ? '-' : '')
  + currentRawNumbers.substring(3,6) + (currentRawNumbers.length > 6 ? '-' : '' )
  + currentRawNumbers.substring(6,10);

  this.phoneNumber = formatted;

}


,  async signup() {
  if (this.AccountFieldsCheck)
    this.UserCreate()

  this.resetTimer()
},
   
    async UserCreate() {







      this.submitted = true;

 
       
      let cleanedPhoneNumber = this.phoneNumber.replace(/-/g, '');
      const LastName = this.tabNameOrTeam == 'Name' ? this.lastName : ''

      
       const Network = process.env.VUE_APP_BASE_URL ? 'UserCreationSQL' : 'UserCreation'
         api.post( Network  +  '?firstName=' +this.firstName +'&lastName=' + LastName + '&occupation=' + this.occupation
          +'&playerNumber=' +this.playerNumber + '&phoneNumber=' + cleanedPhoneNumber
          + '&EventDate=' + this.$route.query.Date + '&Event=' +  this.$route.query.Team
          )
    .then((res) => { 
      this.show = true

        if (res.data === 'PASS')
            {
              this.submitted = false;
            setTimeout(() => {this.show = false;this.$router.push({ path: '/AccountCreated', 
             query: {firstName: this.firstName,lastName:LastName,occupation:this.occupation,playerNumber:this.playerNumber
             ,Team: this.$route.query.Team, Date: this.$route.query.Date
              } });  }, 100)
            }
        else
            {
                this.submitted = false;
                console.log(res.data)
                this.ErrorMessage = res
                setTimeout(() => {this.show = false; this.ErrorMessage = null }, 4000)
            }
        
    })
    .catch((error) => { 
          this.show = true
           this.ErrorMessage = error
          setTimeout(() => {this.show = false; this.ErrorMessage = null}, 3000)
          });
       

    },
  },
    beforeUnmount() {
    // Clear the timer when the component is destroyed
    clearTimeout(this.timer);
  },
}
</script>
<style lang="css" scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;   
}

.row{
  flex-direction: row;
}

.fullBox{
    /* display: flex; */
  display: inline-flex;
/* padding: 10px 48px; */
flex-direction: column;
justify-content: center;
align-items: center;


padding: 16px;
/* flex-direction: column; */
/* align-items: flex-start; */
gap: 20px;
border-radius: 12px;
/* background: var(--basic-white, #FFF); */
/* position:fixed; */
top:300px;
/* right:138px; */
}





.tabsliderBox {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
flex-direction: row;
align-items: flex-start;
padding: 2px;
font-family: 'OpenSans Bold';
 width:100%;
height: 32px;

/* Grey / Light */

background: #E3E8EF;
border-radius: 8px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.innertabSlider{
   width:50%;
height: 28px;
position: relative;

/* Grey / Lightest */

background: #F8FAFC;
border: 0.5px solid rgba(17, 23, 41, 0.04);
box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
border-radius: 6.93px;

/* Inside auto layout */

flex: none;

align-self: stretch;
flex-grow: 1;
}
.innertabSliderOther{
  
width:50%;
height: 28px;
position: relative;

/* Inside auto layout */

flex: none;

align-self: stretch;
flex-grow: 1;
}


.selectOne{
  position: absolute;
height: 16px;

right: 7.5px;
top: calc(50% - 16px/2 - 0px);

font-family: OpenSans Bold;
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 20px;
/* or 154% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.08px;
text-transform: uppercase;

/* Grey / Darkest */

color: #111729;
}


</style>
