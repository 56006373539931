<template>
  

  <v-dialog style="background:#084CA8" :fullscreen="true" v-model="Popup" >
    <NavBar/>

  <div class="text-center" style="align-items:center;justify-content:center;height:80vh;display:flex">
    
     <div  class="CenterThis">
      
          
        <div class="signupBox" style="width:415px;">
          <h1 >Admin Credentials Needed  </h1>
        <input type="password" v-model="password"  class="nameBox nameboxMobile" style="width:95%"  placeholder="Password"/>

        <span v-if="showFail" style="color:grey">Incorrect Password <br/></span>
        
        <div style="width:100%;" >
          <h3 style="left:20px;position:absolute" >
            Pick Team
          </h3>
            <br/>
        <select style=" border-radius:5px; width:300px;height:50px;margin-top:12px;
        border: 3px solid #084CA8;
                   font-size:25px" v-model="selectedTeam">
      <option value="New York Rangers">New York Rangers</option>
      <option value="New York Knicks">New York Knicks</option>
      <option value="Golden State Warriors">Golden State Warriors</option>
    </select>
        </div>

        

        <h1>
        Select Date 
                <br/>
                <input style="background-color:#b8b4b4; border-radius:5px;width:100%;height:50px; margin-right:80px;
                font-size:25px" type="date" v-model="date"  onkeydown="return false"  /></h1>


      <br/><br/><br/>
        <div class="CenterThis" v-if="selectedTeam && password && date " >
      <div      class="submit "      @click="adminSignIn"  ref="submitButton" tabindex="0" style="background-color:#0B6EFD">
    <div      class="submitText"  >Submit</div>
  </div>

</div>

        </div>
     </div>
  </div>
  </v-dialog>
</template>

<script>

import NavBar from './NavBar.vue'


export default {
    props:{
        ForwardRoute:{type: String},
        
        
    },
    data: ()=>({
      Popup:true,
      password:null,
      showFail:false,
      selectedTeam:null,
      date:null,
    }),
    components:{NavBar},
    computed:{
      
    },
    methods:{

      adminSignIn(){
        var selectedTeamFormat = this.selectedTeam ? this.selectedTeam : '' 
        
        
        selectedTeamFormat = selectedTeamFormat.replace(/\s+/g, '').toLowerCase();
        

        if (this.password == 'PhntmEvent$') //PhntmEvent
          {
          this.Popup = false
          this.$emit('pullUsers',{Content: 'VmVyaWZpZWQ=', Team: selectedTeamFormat, Date: this.date})
          this.$router.push({ path:  this.ForwardRoute , query: {Content: 'VmVyaWZpZWQ=', Team: selectedTeamFormat
          , Date: this.date
           } });
          }
        else{
          this.showFail = true
          setTimeout(()=>{this.showFail = false},3000)
        }
      },
       handleKeyPress(event) {
        
      // Check if the "Enter" key is pressed (keyCode 13) or (key "Enter")
      if (event.keyCode === 13 || event.key === 'Enter') {
        // Handle the functionality when Enter is pressed on the div
        this.adminSignIn()
      }
    },

    },
    mounted() {
    // Focus the div element when the component is mounted
    // this.$refs.submitButton.focus();
    // Add a keyup event listener to the whole document
    document.addEventListener('keyup', this.handleKeyPress);
  },
    beforeUnmount() {
    // Remove the keyup event listener when the component is destroyed
    document.removeEventListener('keyup', this.handleKeyPress);
  },
  async created(){
     const x = this.$route.query.Content
    // TkJBX0dvbGRlbi1TdGF0ZV9XYXJyaW9ycy8yMDIzMTAyNC9Tb2NpYWwvMjAyMzEwMjRfVmFuIE5vc3RyYW5kLm1wNA==

    try {
    const decodedString2 = decodeURIComponent(escape(atob(x)));
    if (decodedString2 == 'Verified')
        this.Popup = false
  }
  catch{
    console.log('ErrorDecoding')
  }
  }

}
</script>

<style>
h1{
  font-family: OpenSans Bold;
  color:#084CA8;
}

h3{
  font-family: OpenSans ;
  color:#084CA8;
}
</style>